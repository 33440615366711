(function (d) {
    var config = {
            kitId: 'lwd8tsk',
            scriptTimeout: 3000,
            async: true
        },
        h = d.documentElement, t = setTimeout(function () {
            h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
        }, config.scriptTimeout), tk = d.createElement("script"), f = false, s = d.getElementsByTagName("script")[0], a;
    h.className += " wf-loading";
    tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
    tk.async = true;
    tk.onload = tk.onreadystatechange = function () {
        a = this.readyState;
        if (f || a && a != "complete" && a != "loaded")return;
        f = true;
        clearTimeout(t);
        try {
            Typekit.load(config)
        } catch (e) {
        }
    };
    s.parentNode.insertBefore(tk, s)
})(document);

(function ($) {

    var $scroll, $body, $mfError, clickEvent = 'click tap';

    $(document).ready(function(){

        $scroll = $('html, body');
        $body = $('body');
        $mfError = $('.js-mailfirst-error');

        $body.on(clickEvent, '.js-front-login', function(){
            $('.js-front-login').not(this).removeClass('has-focus');
            $(this).addClass('has-focus');
        });

        $body.on(clickEvent, '.js-btn-nav', function(e){
            $(this).toggleClass('active');
            $('.js-nav-main').toggleClass('active');
            e.stopPropagation();
        });

        $body.on(clickEvent, function(){
            if ($('.js-nav-main').is('.active')) {
                $('.js-btn-nav').removeClass('active');
                $('.js-nav-main').removeClass('active');
            }   
        });

        if ($mfError.length) {
            var scroll = ($mfError.offset().top) - 20;
            $(window).scrollTop(scroll);
        }

        var $header = $('.site-header');

        $(window).scroll(function() {
            var scroll = $(window).scrollTop(), hasScrolled = $header.hasClass('has-scrolled');

            if ($body.hasClass('home')) {
                if (scroll > 0 && !hasScrolled) {
                    $header.addClass('has-scrolled solidHeader');
                }
                else if (scroll == 0 && hasScrolled) {
                    $header.removeClass('has-scrolled solidHeader');
                }
            }
            else {
                //>=, not <=
                if (scroll >= 300) {
                    //clearHeader, not clearheader - caps H
                    $header.addClass("solidHeader");
                } else {
                    if (scroll <= 300) {
                        $header.removeClass("solidHeader");
                    }
                }
            }
        });
        

    });

})(jQuery);